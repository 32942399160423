import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectServicio (Vue, filter, search, sorter, page, idpuestoServicio) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idpuesto_servicio', idpuestoServicio)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('codigo', search)
        .addILike('descripcion', search)
        .addILike('puesto_servicio_descripcion', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.codigo.value) {
      apiFilter.addILike(filter.codigo.field, filter.codigo.value)
    }
    if (filter.descripcion.value) {
      apiFilter.addILike(filter.descripcion.field, filter.descripcion.value)
    }
    const resp = await Vue.$api.call('puestoServicioServicio.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectServicioRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idpuesto_servicio_servicio', pks)
    const resp = await Vue.$api.call('puestoServicioServicio.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
